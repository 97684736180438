import { AbstractMesh, Animation, Engine, FreeCamera, KeyboardEventTypes, KeyboardInfo, Mesh, Scene, Sound, Vector3 } from "@babylonjs/core";
import { useLoadEntity } from "./useLoadEntity";
import { useCallback, useEffect, useState } from "react";

export class Door {
  state: "open" | "closed" | "opening" | "closing" = "closed";
  leftDoor: AbstractMesh;
  rightDoor: AbstractMesh;

  constructor(leftDoor: AbstractMesh, rightDoor: AbstractMesh) {
    this.leftDoor = leftDoor;
    this.rightDoor = rightDoor;
  }
}

export const useDoors = ({
  camera,
  scene,
  onMeshClicked,
  ...rest
}:{
  camera?: FreeCamera;
  scene?: Scene;
  onMeshClicked?: (mesh: AbstractMesh) => void;
}) => {
  const [audioOpenDoors, setAudioOpenDoors] = useState<Sound>();
  const [audioCloseDoors, setAudioCloseDoors] = useState<Sound>();
  const [audioEnabled, setAudioEnabled] = useState<boolean>(false);
  const {LoadEntity} = useLoadEntity({onMeshClicked});
  const [leftDoorPrototype, setLeftDoorPrototype] = useState<AbstractMesh | null>(null);
  const [rightDoorPrototype, setRightDoorPrototype] = useState<AbstractMesh | null>(null);
  const [doors, setDoors] = useState<Door[]>([]);

  useEffect(() => {
    if(!audioOpenDoors && scene) {
      const audio = new Sound("steps", process.env.PUBLIC_URL + '/audio/sliding-door-open.mp3', scene, () => {
        setAudioOpenDoors(audio);
      }, {
        loop: false,
        autoplay: false,
      });
    }

    if(!audioCloseDoors && scene) {
      const audio = new Sound("steps", process.env.PUBLIC_URL + '/audio/sliding-door-close.mp3', scene, () => {
        setAudioCloseDoors(audio);
      }, {
        loop: false,
        autoplay: false,
      });
    }
  }, [scene]);

  useEffect(() => {
    const onClick = (event:any) => {
      if(audioOpenDoors && audioCloseDoors) {
        setAudioEnabled(true);
        Engine.audioEngine?.audioContext?.resume();
      }
    };

    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('click', onClick);
    }
  }, [audioOpenDoors, audioCloseDoors]);

  useEffect(() => {
    if(
      scene === undefined || scene === null ||
      camera === undefined || camera === null ||
      audioOpenDoors === undefined || audioOpenDoors === null ||
      audioCloseDoors === undefined || audioCloseDoors === null ||
      !audioEnabled
    )
      return;

    const observable = scene.onBeforeRenderObservable.add((eventData, eventState) => {
      doors.forEach((door) => {
        const cameraPosition = camera.position;
        const doorPosition = door.leftDoor.position;
        const distance = Vector3.Distance(cameraPosition, doorPosition);
        if (distance <= 10 && door.state === "closed") {
          door.state = "opening";
          OpenDoor(door, () => {
            door.state = "open";
          });
        } else if(distance > 10 && door.state === "open") {
          door.state = "closing";
          CloseDoor(door, () => {
            door.state = "closed";
          });
        }
      });
    });

    return () => {
      scene.onBeforeRenderObservable.remove(observable);
    }
  }, [audioOpenDoors, audioCloseDoors, doors, audioEnabled]);

  const CreateDoor = async (position: Vector3): Promise<Door | null> => {
    if(scene === undefined || scene === null || camera === undefined || camera === null)
      return null;

    let leftDoor: AbstractMesh | null = null;
    if(leftDoorPrototype === null) {
      const leftDoorMeshes = await LoadEntity({
        folder: "./models/Level_1_ZALI_IN/",
        file: "Left_door.glb",
        lightmaps: ["Level_2_OUT.jpg"],
        should_apply_collisions: true
      }, scene);
      leftDoorMeshes.shift();

      leftDoor = Mesh.MergeMeshes(leftDoorMeshes, true, true, undefined, false, true);
      leftDoor?.position.set(position.x - 1.5, 0, 0);
      setLeftDoorPrototype(leftDoor);
    } else {
      leftDoor = leftDoorPrototype.clone("leftDoor", null);
      leftDoor?.position.set(position.x - 1.5, 0, 0);
    }

    let rightDoor: AbstractMesh | null = null;
    if(rightDoorPrototype === null) {
      const rightDoorMeshes = await LoadEntity({
        folder: "./models/Level_1_ZALI_IN/",
        file: "Right_door.glb",
        lightmaps: ["Level_2_OUT.jpg"],
        should_apply_collisions: true,
      }, scene);
      rightDoorMeshes.shift();

      rightDoor = Mesh.MergeMeshes(rightDoorMeshes, true, true, undefined, false, true);
      rightDoor?.position.set(position.x + 1.5, 0, 0);
      setRightDoorPrototype(rightDoor);
    } else {
      rightDoor = rightDoorPrototype.clone("rightDoor", null);
      rightDoor?.position.set(position.x + 1.5, 0, 0);
    }

    if(leftDoor === null)
      return null;

    if(rightDoor === null)
      return null;

    // Listen for collisions with the door
    const door = new Door(leftDoor, rightDoor);

    setDoors([...doors, door]);

    return door;
  }

  // Function to open the door
  const OpenDoor = useCallback((door: Door, callback: () => void) => {
    if(scene === undefined || scene === null)
      return null;

    if(audioOpenDoors?.isPlaying == false && audioEnabled) {
      audioOpenDoors?.setVolume(1);
      audioOpenDoors?.play();
    }

    const doorLeftOpenAnimation = new Animation(
      "doorLeftOpenAnimation",
      "position",
      60,
      Animation.ANIMATIONTYPE_VECTOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const leftDoorKeyframes = [
      {
        frame: 0,
        value: door.leftDoor.position,
      },
      {
        frame: 100,
        value: new Vector3(door.leftDoor.position.x + 1.5, door.leftDoor.position.y, door.leftDoor.position.z),
      },
    ];

    doorLeftOpenAnimation.setKeys(leftDoorKeyframes);
    door.leftDoor.animations = [doorLeftOpenAnimation];
    scene.beginAnimation(door.leftDoor, 0, 100, false);

    const doorRightOpenAnimation = new Animation(
      "doorRightOpenAnimation",
      "position",
      60,
      Animation.ANIMATIONTYPE_VECTOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const rightDoorKeyframes = [
      {
        frame: 0,
        value: door.rightDoor.position,
      },
      {
        frame: 100,
        value: new Vector3(door.rightDoor.position.x - 1.5, door.rightDoor.position.y, door.rightDoor.position.z),
      },
    ];

    doorRightOpenAnimation.setKeys(rightDoorKeyframes);
    door.rightDoor.animations = [doorRightOpenAnimation];
    scene.beginAnimation(door.rightDoor, 0, 100, false, undefined, callback);
  }, [audioOpenDoors, audioEnabled, scene]);

  // Function to close the door
  const CloseDoor = (door: Door, callback: () => void) => {
    if(scene === undefined || scene === null)
      return null;

    if(audioCloseDoors?.isPlaying == false && audioEnabled) {
      audioCloseDoors?.setVolume(1);
      audioCloseDoors?.play();
    }

    const doorLeftCloseAnimation = new Animation(
      "doorLeftCloseAnimation",
      "position",
      60,
      Animation.ANIMATIONTYPE_VECTOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const leftDoorKeyframes = [
      {
        frame: 0,
        value: door.leftDoor.position,
      },
      {
        frame: 100,
        value: new Vector3(door.leftDoor.position.x - 1.5, door.leftDoor.position.y, door.leftDoor.position.z),
      },
    ];

    doorLeftCloseAnimation.setKeys(leftDoorKeyframes);
    door.leftDoor.animations = [doorLeftCloseAnimation];
    scene.beginAnimation(door.leftDoor, 0, 100, false);

    const doorRightCloseAnimation = new Animation(
      "doorRightOpenAnimation",
      "position",
      60,
      Animation.ANIMATIONTYPE_VECTOR3,
      Animation.ANIMATIONLOOPMODE_CONSTANT
    );

    const rightDoorKeyframes = [
      {
        frame: 0,
        value: door.rightDoor.position,
      },
      {
        frame: 100,
        value: new Vector3(door.rightDoor.position.x + 1.5, door.rightDoor.position.y, door.rightDoor.position.z),
      },
    ];

    doorRightCloseAnimation.setKeys(rightDoorKeyframes);
    door.rightDoor.animations = [doorRightCloseAnimation];
    scene.beginAnimation(door.rightDoor, 0, 100, false, undefined, callback);
  }

  return {
    CreateDoor
  }
}
