import { Animation } from "@babylonjs/core";
import { useCallback } from "react"

export const useAnimations = () => {
  const rotationAnimation = useCallback((fps: number, axis: 'x' | 'y' | 'z', direction: 'forward' | 'backward' = 'forward') => {
    const rotateAnim = new Animation(
      "rotateAnim",
      "rotation." + axis,
      fps,
      Animation.ANIMATIONTYPE_FLOAT,
      Animation.ANIMATIONLOOPMODE_CYCLE
    );

    const rotateFrames = [];
    rotateFrames.push({
      frame: 0,
      value: 0,
    });
    rotateFrames.push({
      frame: 180,
      value: direction == 'forward' ? Math.PI * 2 : -Math.PI * 2,
    });

    rotateAnim.setKeys(rotateFrames);

    return rotateAnim;
  }, []);

  return {
    rotationAnimation,
  }
}
