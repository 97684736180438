import React, { useCallback, useEffect, useState } from "react";
import { AbstractMesh } from "@babylonjs/core";
import SceneComponent from "./components/SceneComponent";
import "./App.css";
import LoadingComponent from "./components/LoadingComponent";
import InfoScreenComponent from "./components/InfoScreenComponent";
// @ts-ignore
import Sound from 'react-sound';
import ArteffactScreenComponent from "./components/ArteffactScreenComponent";
import IntroScreenComponent from "./components/IntroScreenComponent";
import { SoundsDataProvider } from "./hooks/useWalkingSounds";

export default () => {
  let box:any;
  const [loading, setLoading] = React.useState<boolean>(true);
  const [completed, setCompleted] = React.useState<boolean>(false);
  const [infoScreenTheme, setInfoScreenTheme] = React.useState<string>("Enter_Front");
  const [arteffactKey, setArteffactKey] = React.useState<string>("altar_table");
  const [introScreenDisplayed, setIntroScreenDisplayed] = React.useState<boolean>(false);
  const [infoScreenDisplayed, setInfoScreenDisplayed] = React.useState<boolean>(false);
  const [arteffactScreenDisplayed, setArteffactScreenDisplayed] = React.useState<boolean>(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState<boolean>(false);

  useEffect(() => {
    const onClick = (event:any) => {
      setIsAudioPlaying(true);
    };

    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('click', onClick);
    }
  }, []);

  const onSceneLoaded = useCallback((scene:any) => {
    setLoading(false);
    setCompleted(true);
    setIntroScreenDisplayed(true);
  }, []);

  const onSceneReady = useCallback((scene:any) => {
  }, []);

  /**
   * Will run on every frame render.  We are spinning the box on y-axis.
   */
  const onRender = useCallback((scene:any) => {
  }, []);

  const onMeshClicked = useCallback((mesh: AbstractMesh) => {
    if(!infoScreenDisplayed) {
      if(mesh.name.includes("D_INFO") || mesh.name.includes("V_INFO")) {
        if(mesh.name.includes("Enter_Front")) {
          setInfoScreenTheme("Enter_Front");
        }

        if(mesh.name.includes("Enter_Back")) {
          setInfoScreenTheme("Enter_Back");
        }

        if(mesh.name.includes("Antichnost_1")) {
          setInfoScreenTheme("Zala_3_Antichnost");
        }

        if(mesh.name.includes("Antichnost_3")) {
          setInfoScreenTheme("Zala_4_1_Antichnost");
        }

        if(mesh.name.includes("Antichnost_2")) {
          setInfoScreenTheme("Zala_4_2_Antichnost");
        }

        if(mesh.name.includes("XP_Obshta")) {
          setInfoScreenTheme("Zala_Hristianstvo");
        }

        if(mesh.name.includes("Zala_Ranno_XP_1")) {
          setInfoScreenTheme("Zala_RannoXristianstvo");
        }

        if(mesh.name.includes("Zala_Ranno_XP_2") || mesh.name.includes("Zala_Ranno_XP_3")) {
          setInfoScreenTheme("Zala_RannoXristianstvoArt");
        }

        if(mesh.name.includes("Vazr_1")) {
          setInfoScreenTheme("Zala_Vazrazhdane_Main");
        }

        if(mesh.name.includes("Vazr_2")) {
          setInfoScreenTheme("Zala_Hristovite_Voini");
        }

        if(mesh.name.includes("Vazr_3")) {
          setInfoScreenTheme("Zala_Hristovite_Voini");
        }

        setInfoScreenDisplayed(true);
      }

      if(!mesh.name.includes("D_INFO") && !mesh.name.includes("K_INFO") && !mesh.name.includes("V_INFO") && mesh.name.includes("IlluminatingBoard")) {
        let meshName = mesh.name;
        meshName = meshName.replace("_IlluminatingBoard", "");
        meshName = meshName.replace("Oko_", "");
        meshName = meshName.replace("Eff_", "");
        meshName = meshName.replace("Art_", "");

        if(meshName == "Antichnost_1") {
          setArteffactKey("tantzuvashti_nimfi_goli");
        } else if(meshName == "Antichnost_2") {
          setArteffactKey("obrochna_plocha_dionis");
        } else if(meshName == "Antichnost_3") {
          setArteffactKey("lion_head");
        } else if(meshName == "Antichnost_4") {
          setArteffactKey("bronzova_panther");
        } else if(meshName == "Antichnost_5") {
          setArteffactKey("solaren_kultov_predmet");
        } else if(meshName == "Antichnost_6") {
          setArteffactKey("herakl");
        } else if(meshName == "Antichnost_7") {
          setArteffactKey("black_krater");
        } else if(meshName == "Antichnost_8") {
          setArteffactKey("black_skifos");
        } else if(meshName == "Antichnost_9") {
          setArteffactKey("bronze_dagger");
        } else if(meshName == "Antichnost_10") {
          setArteffactKey("bronze_pomegranate");
        } else if(meshName == "RXp_11") {
          setArteffactKey("silver_hand");
        } else if(meshName == "RXp_12") {
          setArteffactKey("silver_reliquary");
        } else if(meshName == "RXp_13") {
          setArteffactKey("silver_spoon");
        } else if(meshName == "RXp_14") {
          setArteffactKey("iron_cross");
        } else if(meshName == "RXp_15") {
          setArteffactKey("altar_table");
        } else if(meshName == "Vazr_16") {
          setArteffactKey("nagradna_ikona");
        } else if(meshName == "Vazr_17") {
          setArteffactKey("sveti_georgi_malka");
        } else if(meshName == "Vazr_18") {
          setArteffactKey("sveti_georgi_goliama");
        } else if(meshName == "Vazr_19") {
          setArteffactKey("sveti_dimitar_malka");
        } else if(meshName == "Vazr_20") {
          setArteffactKey("sveti_dimitar_goliama");
        }

        setArteffactScreenDisplayed(true);
      }
    }
  }, []);

  const onInfoScreenClosed = useCallback(() => {
    setInfoScreenDisplayed(false);
  }, []);

  const onArteffactScreenClosed = useCallback(() => {
    setArteffactScreenDisplayed(false);
  }, []);

  return (
    <div style={{
      width: "100% !important",
      height: "100% !important",
    }}>
      <SoundsDataProvider>
        <LoadingComponent loading={loading} completed={completed} />

        <SceneComponent
          antialias
          id="canvas"
          onRender={onRender}
          onMeshClicked={onMeshClicked}
          onSceneReady={onSceneReady}
          onSceneLoaded={onSceneLoaded}
        />

        {
          introScreenDisplayed ? (
            <IntroScreenComponent onClose={() => {
              setIntroScreenDisplayed(false);
            }} />
          ) : null
        }

        {
          infoScreenDisplayed ? (
            <InfoScreenComponent onClose={onInfoScreenClosed} theme={infoScreenTheme} />
          ) : null
        }

        {
          arteffactScreenDisplayed ? (
            <ArteffactScreenComponent
              antialias
              arteffactKey={arteffactKey}
              id="arteffact-canvas"
              onClose={onArteffactScreenClosed} />
          ) : null
        }

        {/* <Sound
          loop={true}
          playStatus={isAudioPlaying ? Sound.status.PLAYING : Sound.status.PAUSED}
          url={process.env.PUBLIC_URL + '/audio/Intro.mp3'}
          volume={3}
        /> */}
      </SoundsDataProvider>
    </div>
  )
};
