// @ts-ignore
import { useEffect, useState } from "react";
import { useWalkingSounds } from "../hooks/useWalkingSounds";
// @ts-ignore
import Slider from "react-slick";
import {themes} from "../data/themes";

const InfoScreenComponentIcon = (props: {
  children: any;
}) => {
  return (
    <div className="InfoScreenComponentIcon">
      {props.children}
    </div>
  );
}

export default (props: {
  theme: string;
  onClose?: () => void;
}) => {
  const {playBackgroundMusic} = useWalkingSounds();
  const { onClose, theme } = props;
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [themeContent, setThemeContent] = useState<any>(
    // @ts-ignore
    themes[theme] !== undefined ? themes[theme] : themes["Enter_Front"]
  );
  const [subthemeContent, setSubthemeContent] = useState<any>(null);

  const sliderSettings = {
    dots: false,
    infinite: false,
    initialSlide: currentSlide,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex);
    }
  };

  useEffect(() => {
    if(['Zala_Hristianstvo', 'Zala_RannoXristianstvo', 'Zala_RannoXristianstvoArt', 'Zala_Vazrazhdane_Main', 'Zala_Hristovite_Voini'].includes(theme)) {
      playBackgroundMusic('vizantia');
    } else if(['Zala_3_Antichnost', 'Zala_4_1_Antichnost', 'Zala_4_2_Antichnost'].includes(theme)) {
      playBackgroundMusic('antichnost');
    } else {
      playBackgroundMusic('intro');
    }
  }, [theme]);

  return (
    <>
      <div className="screen-wallpaper">
        <div className="curved-background-box">
          <fieldset className="screen-wallpaper-fieldset">
            <legend>
              <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="logo" className="logo" />
            </legend>
          </fieldset>
        </div>
        <div className="curved-background-container"></div>
      </div>
      <div className="InfoScreenComponent">
        <div className="InfoScreenComponentContent">
          <div className="InfoScreenComponentContentHeader">
            {
              themeContent?.subthemes !== undefined && subthemeContent == null ? (
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                }}>
                  {
                    themeContent.subthemes.map((subtheme: any, index: number) => (
                      <InfoScreenComponentIcon>
                        <img src={process.env.PUBLIC_URL + subtheme.thumbnail} />
                      </InfoScreenComponentIcon>
                    ))
                  }
                </div>
              ) : null
            }

            {
              subthemeContent !== null ? (
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                }}>
                  <a className="ButtonBack" href="#" onClick={(e) => {
                    setSubthemeContent(null);
                  }}></a>
                  <InfoScreenComponentIcon>
                    <img src={process.env.PUBLIC_URL + subthemeContent.thumbnail} />
                  </InfoScreenComponentIcon>
                </div>
              ) : null
            }

            {
              subthemeContent !== null ? (
                <h1 dangerouslySetInnerHTML={{
                  __html: `${subthemeContent.title}`
                }}></h1>
              ) : (
                <h1 style={{
                  textAlign: themeContent?.subthemes == undefined ? "center" : 'right',
                  width: "100%",
                }} dangerouslySetInnerHTML={{
                  __html: `${themeContent.title}`
                }}></h1>
              )
            }
          </div>
          <div className="InfoScreenComponentContentContent">
            {
              themeContent.html !== undefined ? (
                <div dangerouslySetInnerHTML={{
                  __html: `${themeContent.html}`
                }}></div>
              ) : null
            }

            {
              subthemeContent !== null? (
                <div dangerouslySetInnerHTML={{
                  __html: `${subthemeContent.html}<p style="text-align: center;"><img src="${process.env.PUBLIC_URL}${subthemeContent.detailPicture}" /></p>`
                }}></div>
              ) : null
            }

            {
              themeContent?.subthemes !== undefined && subthemeContent == null ? (
                <Slider {...sliderSettings}>
                  {themeContent.subthemes.map((subtheme: any, index: number) => {
                    return (
                      <div className="gallerySlideWrap" key={index} onClick={() => {
                        setSubthemeContent(subtheme);
                      }}>
                        <div className="gallerySlide" key={index}>
                          <div className="gallerySlideImage" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}${subtheme.picture})`,
                            backgroundSize: "cover",
                          }}></div>
                          <div className="gallerySlideContent">
                            <div className="gallerySlideIcon" style={{
                              backgroundImage: `url(${process.env.PUBLIC_URL}${subtheme.thumbnail})`,
                              backgroundSize: "cover",
                            }}></div>
                            <div className="gallerySlideText">
                              <h3>{subtheme.title}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </Slider>
              ) : null
            }
          </div>
          <div className="InfoScreenComponentContentFooter">
            <a className="back" href="#" onClick={(e) => {
              e.preventDefault();
              onClose?.();
              playBackgroundMusic('intro');
            }}>НАЗАД</a>
          </div>
        </div>
      </div>
    </>
  )
}
