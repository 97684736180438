import { Vector3 } from "@babylonjs/core";
import { IVideo } from "../interfaces/IVideo";

export const sceneVideos: IVideo[]  = [
  {
    activationDistance: 5,
    height: 1.7,
    muted: false,
    name: 'HologramNataliya',
    path: 'video/HologramNataliya.mp4',
    position: new Vector3(11.13533, 2.053148, -15.38926),
    rotation: new Vector3(0, -Math.PI/2, 0),
    width: 0.85,
    videoName: 'HologramNataliya',
  },
  {
    activationDistance: 5,
    height: 1.7,
    muted: false,
    name: 'HologramKristiana',
    path: 'video/HologramKristiana.mp4',
    position: new Vector3(25.10162, 2.053148, -15.38926),
    rotation: new Vector3(0, Math.PI/2, 0),
    width: 0.85,
    videoName: 'HologramKristiana',
  },
  {
    activationDistance: 15,
    height: 0.73,
    muted: true,
    name: 'Zala_Antichnost_1',
    path: 'video/HologramHorizontal.mp4',
    position: new Vector3(-9.165248, 1.7437254, -13.3564),
    rotation: new Vector3(0, Math.PI/2, 0),
    width: 0.964,
    videoName: 'HologramHorizontal',
  },
  {
    activationDistance: 15,
    height: 0.73,
    muted: true,
    name: 'Zala_Antichnost_2',
    path: 'video/HologramHorizontal.mp4',
    position: new Vector3(-21.70405, 1.743725, -19.32798),
    rotation: new Vector3(0, Math.PI, 0),
    width: 0.964,
    videoName: 'HologramHorizontal',
  },
  {
    activationDistance: 15,
    height: 0.73,
    muted: true,
    name: 'Zala_Antichnost_3',
    path: 'video/HologramHorizontal.mp4',
    position: new Vector3(-21.37992, 1.7435, -11.46233),
    rotation: new Vector3(0, 0, 0),
    width: 0.964,
    videoName: 'HologramHorizontal',
  },
  {
    activationDistance: 15,
    height: 0.73,
    muted: true,
    name: 'Zala_Vazr_1',
    path: 'video/HologramHorizontal.mp4',
    position: new Vector3(10.58654, 1.731583, -9.962454),
    rotation: new Vector3(0, Math.PI, 0),
    width: 0.964,
    videoName: 'HologramHorizontal',
  },
  {
    activationDistance: 15,
    height: 0.73,
    muted: true,
    name: 'Zala_Vazr_2',
    path: 'video/HologramHorizontal.mp4',
    position: new Vector3(18.8796, 1.743733, -2.093631),
    rotation: new Vector3(0, 0, 0),
    width: 0.964,
    videoName: 'HologramHorizontal',
  },
  {
    activationDistance: 15,
    height: 0.73,
    muted: true,
    name: 'Zala_Vazr_3',
    path: 'video/HologramHorizontal.mp4',
    position: new Vector3(18.53712, 1.731583, -9.967592),
    rotation: new Vector3(0, Math.PI, 0),
    width: 0.964,
    videoName: 'HologramHorizontal',
  },
  {
    activationDistance: 15,
    height: 0.73,
    muted: true,
    name: 'Zala_Ranno_XP_1',
    path: 'video/HologramHorizontal.mp4',
    position: new Vector3(10.91517, 1.731576, -20.95365),
    rotation: new Vector3(0, 0, 0),
    width: 0.964,
    videoName: 'HologramHorizontal',
  },
  {
    activationDistance: 15,
    height: 0.73,
    muted: true,
    name: 'Zala_Ranno_XP_2',
    path: 'video/HologramHorizontal.mp4',
    position: new Vector3(18.8661, 1.731576, -20.9485),
    rotation: new Vector3(0, 0, 0),
    width: 0.964,
    videoName: 'HologramHorizontal',
  },
  {
    activationDistance: 15,
    height: 0.73,
    muted: true,
    name: 'Zala_Ranno_XP_2',
    path: 'video/HologramHorizontal.mp4',
    position: new Vector3(18.54933, 1.743725, -28.82247),
    rotation: new Vector3(0, Math.PI, 0),
    width: 0.964,
    videoName: 'HologramHorizontal',
  },
  {
    activationDistance: 15,
    height: 1.284,
    muted: true,
    name: 'Enter_Front_1',
    path: 'video/HologramVertical.mp4',
    position: new Vector3(3.58566, 1.67825, -0.4514481),
    rotation: new Vector3(0, Math.PI/2, 0),
    width: 0.989,
    videoName: 'HologramVertical',
  },
  {
    activationDistance: 15,
    height: 1.284,
    muted: true,
    name: 'Enter_Front_2',
    path: 'video/HologramVertical.mp4',
    position: new Vector3(-6.367752, 1.678395, -0.4514535),
    rotation: new Vector3(0, -Math.PI/2, 0),
    width: 0.989,
    videoName: 'HologramVertical',
  },
  {
    activationDistance: 15,
    height: 1.284,
    muted: true,
    name: 'Enter_Back',
    path: 'video/HoologramUnion.mp4',
    position: new Vector3(-1.38437, 1.677963, -19.35704),
    rotation: new Vector3(0, Math.PI, 0),
    width: 0.989,
    videoName: 'HoologramUnion',
  },
  {
    activationDistance: 15,
    height: 0.94,
    muted: true,
    name: 'XP_Obshta_1',
    path: 'video/HologramChristian.mp4',
    position: new Vector3(6.389839, 1.720114, -18.1383),
    rotation: new Vector3(0, -Math.PI/2, 0),
    width: 0.7,
    videoName: 'HologramChristian',
  },
  {
    activationDistance: 15,
    height: 0.94,
    muted: true,
    name: 'XP_Obshta_2',
    path: 'video/HologramChristian.mp4',
    position: new Vector3(6.389838, 1.720382, -12.65253),
    rotation: new Vector3(0, -Math.PI/2, 0),
    width: 0.7,
    videoName: 'HologramChristian',
  },
];
