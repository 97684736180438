import { Vector3 } from "@babylonjs/core";
import { IFileData } from "../interfaces/IFileData";

export const people:IFileData[] = [
  {
    folder: "./models/people/",
    file: "04_person.glb",
    should_apply_collisions: false,
    position: new Vector3(0, 0, -16.5)
  },
  {
    folder: "./models/people/",
    file: "16_person.glb",
    should_apply_collisions: false,
    position: new Vector3(11.4, 0, -3.8),
    rotation: new Vector3(0, Math.PI / 2, 0)
  },
  {
    folder: "./models/people/",
    file: "33_person.glb",
    should_apply_collisions: false,
    position: new Vector3(22.6, 0, -8.72),
    rotation: new Vector3(0, Math.PI / 4, 0)
  },
  {
    folder: "./models/people/",
    file: "36_person.glb",
    should_apply_collisions: false,
    position: new Vector3(-4.916, 0, -10.028),
    rotation: new Vector3(0, -Math.PI / 4, 0)
  },
  {
    folder: "./models/people/",
    file: "80_person.glb",
    should_apply_collisions: false,
    position: new Vector3(0, 0, 0)
  },
  {
    folder: "./models/people/",
    file: "119_person.glb",
    should_apply_collisions: false,
    position: new Vector3(15.88, 0, -24.53)
  },
  {
    folder: "./models/people/",
    file: "121_person.glb",
    should_apply_collisions: false,
    position: new Vector3(-13.85, 0, -12.26)
  },
  {
    folder: "./models/people/",
    file: "134_person.glb",
    should_apply_collisions: false,
    position: new Vector3(2.476, 0, -7.526)
  },
  {
    folder: "./models/people/",
    file: "135_person.glb",
    should_apply_collisions: false,
    position: new Vector3(7.02, 0, -26.036),
    rotation: new Vector3(0, -Math.PI / 4, 0)
  },
  {
    folder: "./models/people/",
    file: "142_person.glb",
    should_apply_collisions: false,
    position: new Vector3(31.15, 0, -22.4),
    rotation: new Vector3(0, Math.PI / 4, 0)
  },
  {
    folder: "./models/people/",
    file: "152_person.glb",
    should_apply_collisions: false,
    position: new Vector3(0, 0, 0)
  },
  {
    folder: "./models/people/",
    file: "156_person.glb",
    should_apply_collisions: false,
    position: new Vector3(30.2, 0, -13.42)
  },
  {
    folder: "./models/people/",
    file: "192_person.glb",
    should_apply_collisions: false,
    position: new Vector3(2.177, 0, 0.806)
  },
  {
    folder: "./models/people/",
    file: "229_person.glb",
    should_apply_collisions: false,
    position: new Vector3(21.7, 0, -12.73),
    rotation: new Vector3(0, Math.PI /4, 0)
  },
  {
    folder: "./models/people/",
    file: "212_person.glb",
    should_apply_collisions: false,
    position: new Vector3(-30.02992, 0, 39.00879),
    rotation: new Vector3(0, Math.PI, 0)
  },
  {
    folder: "./models/people/",
    file: "291_person.glb",
    should_apply_collisions: false,
    position: new Vector3(-19.38, 0, -18.54),
    rotation: new Vector3(0, -Math.PI / 2, 0)
  },
];
