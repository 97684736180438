// @ts-ignore
import { useRef, useState } from "react";
// @ts-ignore
import Slider from "react-slick";

export default (props: {
  onClose?: () => void;
}) => {
  const { onClose } = props;
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const pictures = [
    '/images/manual/manual-keyboard.png',
    '/images/manual/manual-plan.png',
    '/images/manual/manual-preset.png',
    '/images/manual/manual-view-more.png',
    '/images/manual/manual-arteffacts.png'
  ];

  const sliderRef = useRef(null);

  const handleNextSlide = () => {
    if(!sliderRef || !sliderRef.current) return;
    // @ts-ignore
    sliderRef?.current?.slickNext();
  };

  const handlePrevSlide = () => {
    if(!sliderRef || !sliderRef.current) return;

    // @ts-ignore
    sliderRef.current.slickPrev();
  };

  const sliderSettings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex);
    }
  }

  return (
    <>
      <div className="IntroScreenComponent">
        <div className="IntroScreenComponentHeader">
          <div style={{ display: 'flex' }}>
          {
            currentSlide > 0 && (
              <a className="ButtonBack Text" href="#" onClick={handlePrevSlide}>НАЗАД</a>
            )
          }
          </div>
          <img src={process.env.PUBLIC_URL + '/images/logo-white.png'} style={{ height: 50 }} />
          <div style={{ display: 'flex', position: 'relative', height: '100%', alignItems: 'center' }}>
          {
            currentSlide == 1 && (
              <span style={{
                color: '#5b6d81',
                fontSize: '20px',
                fontWeight: 'bold',
                marginTop: '2px',
                minWidth: '100px',
                position: 'absolute',
                right: '80px',
                textAlign: 'right'
              }}>ПЛАН<br/>на залите</span>
            )
          }

          {
            currentSlide == 4 && (
              <span style={{
                color: '#5b6d81',
                fontSize: '20px',
                fontWeight: 'bold',
                marginTop: '2px',
                minWidth: '100px',
                position: 'absolute',
                right: '80px',
                textAlign: 'right'
              }}>АРТЕФАКТ</span>
            )
          }

          {
            currentSlide < pictures.length - 1 && (
              <a className="ButtonNext Text" href="#" onClick={handleNextSlide}>НАПРЕД</a>
            )
          }
          </div>
        </div>
        <div className="IntroScreenComponentContent">
          {
            <Slider ref={sliderRef} {...sliderSettings}>
              {pictures.map((picture: any, index: number) => {
                return (
                  <img src={process.env.PUBLIC_URL + picture} style={{
                    width: 'auto', display: 'block', margin: '0 auto'
                  }} />
                )
              })}
            </Slider>
          }
        </div>
        <div className="IntroScreenComponentFooter">
          <div style={{
            minHeight: 74,
          }}>
            {
              currentSlide == pictures.length - 1 && (
                <a href="#" onClick={(e) => {
                  e.preventDefault();
                  onClose?.();
                }}>НАЧАЛО</a>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}
