// @ts-nocheck
import { AbstractMesh, ActionManager, ArcRotateCamera, Color3, Color4, CubeTexture, Engine, EngineOptions, FreeCamera, HemisphericLight, MeshBuilder, Scene, SceneOptions, Vector3, Effect, DynamicTexture, ShaderMaterial, Mesh, Animation, CubicEase, EasingFunction, Tools, Matrix, Nullable } from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";
import "@babylonjs/loaders";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLoadEntity } from "../hooks/useLoadEntity";
import { IArteffactData } from "../interfaces/IArteffactData";
import { arteffacts } from "../data/arteffacts";

const SPEED_RATIO = 4;
const LOOP_MODE = false;
const FROM_FRAME = 0;
const TO_FRAME = 100;
const FRAMES_PER_SECOND = 60;
const hotpointColor = "#22211f";
const hotpointTextColor = "#cdb15d";
const activeHotpointColor = "#cdb15d";
const activeHotpointTextColor = "#22211f";
const font = "bold 80px Arial";

export default ({
  arteffactKey,
  adaptToDeviceRatio,
  antialias,
  engineOptions,
  sceneOptions,
  onClose,
  id,
  ...rest
}:{
  arteffactKey: string;
  antialias?: boolean;
  engineOptions?: EngineOptions;
  adaptToDeviceRatio?: boolean;
  sceneOptions?: SceneOptions;
  id?: string;
  onClose?: () => void;
}) => {
  const reactCanvas = useRef(null);
  const [camera, setCamera] = useState<ArcRotateCamera>();
  const [engine, setEngine] = useState<Engine>();
  const [scene, setScene] = useState<Scene>();
  const [renderer, setRenderer] = useState<any>();
  const [arteffactMeta, setArtefactMeta] = useState<IArteffactData>(arteffacts[arteffactKey]);
  const [arteffact, setArteffact] = useState<Mesh>();
  const [activeHotpoint, setActiveHotpoint] = useState<any>();
  const [hotpoints, setHotpoints] = useState<any[]>([]);
  const [hotpointInfo, setHotpointInfo] = useState<any>({});
  const [showInfoWindow, setShowInfoWindow] = useState<boolean>(false);
  const [infoWindowPosition, setInfoWindowPosition] = useState<Vector3>(Vector3.Zero());
  const {LoadEntity} = useLoadEntity({
    onMeshClicked: () => {},
    scene
  });
  const lightConfigurations: any[] = [
    {alpha: 0, beta: 1, gamma: 0, intensity: arteffactMeta.light_intensity, diffuse: new Color3(1, 1, 1), specular: new Color3(1, 1, 1), enabled: true},
    // {alpha: 0.1, beta: 0, gamma: 0, intensity: 5, diffuse: new Color3(1, 0, 0), specular: new Color3(1, 1, 1), enabled: true},
    // {alpha: 120, beta: 0, gamma: 0, intensity: 5, diffuse: new Color3(0, 1, 0), specular: new Color3(1, 1, 1), enabled: true},
    // {alpha: 240, beta: 0, gamma: 0, intensity: 5, diffuse: new Color3(0, 0, 1), specular: new Color3(1, 1, 1), enabled: true},
  ];
  // Variables to store pointer information
  const [isDragging, setIsDragging] = useState(false);
  const [pickedMesh, setPickedMesh] = useState<AbstractMesh|null>(null);
  const [startingPoint, setStartingPoint] = useState<Vector3>(Vector3.Zero());

  // Listeners
  const pointerDownCallback = useCallback((event: any) => {
    if(scene === undefined || scene === null)
      return;

    // Check if the pointer down event is on the box
    var pickResult = scene.pick(scene.pointerX, scene.pointerY);
    if(pickResult.pickedMesh !== null) {
      setPickedMesh(pickResult.pickedMesh);
      // Set dragging flag and store starting point
      setIsDragging(true);
      setStartingPoint(pickResult.pickedPoint.clone());
    }
  }, [isDragging, scene, pickedMesh]);

  const pointerMoveCallback = useCallback((event: any) => {
    if(scene === undefined || scene === null)
      return;

    // Check if dragging
    if (!isDragging) return;

    // Calculate the offset from the starting point
    var pickResult = scene.pick(scene.pointerX, scene.pointerY);
    if (!pickResult.hit) return;

    // Move the box based on the pointer movement
    var current = pickResult.pickedPoint.clone();
    var delta = current.subtract(startingPoint);

    if(pickedMesh !== null) {
      pickedMesh.position.addInPlace(delta);
    }

    // Update starting point for the next move
    setStartingPoint(current);
  }, [isDragging, scene, pickedMesh]);

  const pointerUpCallback = useCallback((event: any) => {
    setIsDragging(false);
  }, [isDragging, scene, pickedMesh]);

  useEffect(() => {
    if(scene === undefined || scene === null)
      return;

    const { current: canvas } = reactCanvas;

    if (!canvas) return;

    // // Register pointer down event
    // canvas.addEventListener("pointerdown", pointerDownCallback);

    // // Register pointer move event
    // canvas.addEventListener("pointermove", pointerMoveCallback);

    // // Register pointer up event
    // canvas.addEventListener("pointerup", pointerUpCallback);

    // return () => {
    //   // Unregister pointer down event
    //   canvas.removeEventListener("pointerdown", pointerDownCallback);

    //   // Unregister pointer move event
    //   canvas.removeEventListener("pointermove", pointerMoveCallback);

    //   // Unregister pointer up event
    //   canvas.removeEventListener("pointerup", pointerUpCallback);
    // }
  }, [isDragging, scene, pickedMesh]);

  // set up basic engine and scene
  useEffect(() => {
    const { current: canvas } = reactCanvas;

    if (!canvas) return;

    Effect.ShadersStore["customVertexShader"]= `
		precision highp float;
    	attribute vec3 position;
    	attribute vec2 uv;
    	uniform mat4 worldViewProjection;
    	varying vec2 vUV;
    	void main(void) {
    	    gl_Position = worldViewProjection * vec4(position, 1.0);
    	    vUV = uv;
    	}`;

    Effect.ShadersStore["customFragmentShader"]=`
	    precision highp float;
        varying vec2 vUV;
        uniform vec2 screenSize;
    	uniform sampler2D textureSampler;
        uniform highp sampler2D depth;
       	void main(void) {
            vec2 coords = gl_FragCoord.xy / screenSize;
            float distanceToCenter = length(vUV - vec2(0.5,0.5));
            float d = texture2D(depth, coords).x;
            float z = gl_FragCoord.z;
            float a = z > d ? 0.3 : 1.0;
            a = distanceToCenter < 0.5 ? a : 0.0;
            float plop = distanceToCenter < 0.45 ? 1.0 : .8;
            gl_FragColor = vec4(texture2D(textureSampler, vUV).xyz * plop, a);
    	}`;

    CreateScene();
  }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions]);

  // bind scene and engine actions
  useEffect(() => {
    if (!scene || !engine) return;

    LoadScene();

    engine.runRenderLoop(() => {
      scene.render();
    });
  }, [scene, engine]);

  useEffect(() => {
    if(hotpoints !== null && hotpoints !== undefined && hotpoints.length > 0) {
      CreatePickingRay();
    }

    window.addEventListener('click', HandleWindowOutsideClick);

    return () => {
      window.removeEventListener('click', HandleWindowOutsideClick);
    };
  }, [hotpoints]);

  const HandleWindowOutsideClick = (event: any) => {
    if (!hotpoints.some(hotpoint => hotpoint === event.target)) {
      setShowInfoWindow(false);
    }
  }

  const LoadScene = async () : Promise<void> => {
    if (!scene || !engine) return;

    CreateLights();
    await CreateArteffact();
  }

  const CreateScene = () => {
    const { current: canvas } = reactCanvas;
    const engine = new Engine(canvas, true, engineOptions, adaptToDeviceRatio);
    setEngine(engine);

    const scene = new Scene(engine, sceneOptions);
    scene.actionManager = new ActionManager(scene);

    const framesPerSecond = 60;
    const gravity = -9.81;
    scene.gravity = new Vector3(0, gravity / framesPerSecond, 0);
    scene.collisionsEnabled = true;
    scene.clearColor = new Color4(0, 0, 0, 0);
    setScene(scene);

    const camera = new ArcRotateCamera("camera", Math.PI / 2, Math.PI / 2, 5, new Vector3(0, 0, 0), scene);
    camera.rotation = new Vector3(0, Math.PI, 0);
    camera.attachControl(canvas, true);
    camera.minZ = 0.45;
    camera.speed = 0.3;
    camera.lowerRadiusLimit = 3;
    camera.radius = 5;
    camera.upperRadiusLimit = 8;
    camera.wheelPrecision = 100;

    setCamera(camera);

    const r = scene.enableDepthRenderer(camera, true);
    setRenderer(r);
  }

  const CreateLights = (): void => {
    const envTex = CubeTexture.CreateFromPrefilteredData(
      "./environment/sky.env",
      scene
    );

    scene.environmentTexture = envTex;

    lightConfigurations.map((lightConfig, index) => {
      const light = new HemisphericLight(
        "light" + index,
        new Vector3(
          lightConfig.alpha,
          lightConfig.beta,
          lightConfig.gamma
        ),
        scene
      );
      light.intensity = lightConfig.intensity;
      light.diffuse = lightConfig.diffuse;
      light.specular = lightConfig.specular;
      light.setEnabled(lightConfig.enabled);

      // const slider = new GUI.Slider();
      // slider.minimum = -6;
      // slider.maximum = 6;
      // slider.value = 0;
      // slider.color = "blue";
      // slider.thumbColor = "white";
      // slider.height = "20px";
      // slider.width = "200px";
      // slider.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;

      // const sliderLight = light;
      // slider.onValueChangedObservable.add((value) => {
      //   if (sliderLight) {
      //     sliderLight.direction.z = value;
      //   }
      // });

      // const advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("gui", true, scene);
      // advancedTexture.addControl(slider);
    });
  }

  const CreateArteffact = async () : Promise<void> => {
    if(scene === undefined || scene === null)
      return;

    const hotpointsData = arteffactMeta.hotpoints;
    const hotpoints:any[] = [];

    const arteffactMeshes = await LoadEntity({
      folder: "./models/arteffacts/",
      file: arteffactMeta.file,
      should_apply_collisions: false,
      scaling: arteffactMeta.scale,
      position: new Vector3(
        arteffactMeta.position.x * arteffactMeta.scale,
        arteffactMeta.position.y * arteffactMeta.scale,
        arteffactMeta.position.z * arteffactMeta.scale,
      ),
      rotation: arteffactMeta.rotation,
    }, scene);

    let localArteffact:Mesh;
    if(arteffactMeshes.length > 0) {
      localArteffact = arteffactMeshes[0];
      setArteffact(localArteffact);
    }

    await Promise.all(hotpointsData.map(async (hotpoint): Promise<void> => {
      const point = await CreateHotpoint(
        scene,
        new Vector3(
          hotpoint.position.x * arteffactMeta.scale,
          hotpoint.position.y * arteffactMeta.scale,
          hotpoint.position.z * arteffactMeta.scale,
        ),
        hotpoint.value
      );
      hotpoints.push(point);
      return Promise.resolve();
    }));

    const dt = engine?.getDepthFunction();

    hotpoints.forEach((spr, index) => {
      spr.onBeforeRenderObservable.add(() => {
        var pos = spr.position.clone();
        pos.multiplyInPlace(new Vector3(2,2,2));
        pos = pos.subtract(camera?.position.clone());
        spr.lookAt(pos);
        engine?.setDepthFunction(Engine.ALWAYS);
      });

      spr.onAfterRenderObservable.add(() => {
        if(dt !== undefined || dt !== null)
        // @ts-ignore
        engine?.setDepthFunction(dt);
      });
    });

    setHotpoints(hotpoints);
  }

  const CreateHotpoint = async (scene:Scene, position: Vector3, value: string) : Promise<Mesh> => {
    const tex = new DynamicTexture("hotpointTexture" + value, { width: 150, height: 150 }, scene);
    tex.drawText(value, null, null, font, hotpointTextColor, hotpointColor, true, true);

    const spriteMaterial = new ShaderMaterial("mat", scene, {
      vertex: "custom",
      fragment: "custom",
    }, {
			attributes: ["position", "uv"],
			uniforms: ["worldViewProjection", "screenSize", "color"],
      needAlphaBlending: true
    });

    spriteMaterial.setVector3("color", new Color3(1.0, 1.0, 0.0));

    spriteMaterial.setTexture("depth", renderer.getDepthMap());
    spriteMaterial.setTexture("textureSampler", tex);
    spriteMaterial.backFaceCulling = false;
    spriteMaterial.disableDepthWrite = true;
    spriteMaterial.alphaMode = Engine.ALPHA_COMBINE;

    const sprite = MeshBuilder.CreatePlane("hotpointSprite" + value, { size: arteffactMeta.hotpointSize, sideOrientation: Mesh.BACKSIDE }, scene);

    sprite.material = spriteMaterial;
    sprite.position = position;
    // sprite.rotation = rotation;

    return sprite;
  }

  const CreatePickingRay = () => {
    if(scene === undefined || scene === null || camera === undefined || camera === null || engine === undefined || engine === null)
      return;

    scene.onPointerDown = () => {
      const ray = scene.createPickingRay(
        scene.pointerX,
        scene.pointerY,
        Matrix.Identity(),
        camera
      );

      // const raycastHit = scene.pickWithRay(ray);
      const hits = scene.multiPickWithRay(ray);

      if (hits !== null && hits.length > 0) {
        for (var i = 0; i < hits.length; i++) {
          if(
            hits[i].pickedMesh !== null &&
            hits[i].pickedMesh !== undefined &&
            hits[i].pickedMesh?.name.includes("hotpointSprite")
            ) {
            hotpoints.forEach((hotpoint, index) => {
              const hotpointTexture = hotpoint.material._textures.textureSampler;
              const value = parseInt(hotpoint.name.replace("hotpointSprite", "") ?? "");
              hotpointTexture.drawText(value, null, null, font, hotpointTextColor, hotpointColor, true, true);
            });

            const value = parseInt(hits[i].pickedMesh?.name.replace("hotpointSprite", "") ?? "");
            const texture = hits[i].pickedMesh?.material._textures.textureSampler;
            texture.drawText(value, null, null, font, activeHotpointTextColor, activeHotpointColor, true, true);

            const hotpointsData = arteffactMeta.hotpoints;

            moveActiveCamera(scene, {
              alpha: hotpointsData[value - 1].alpha,
              beta: hotpointsData[value - 1].beta,
              target: new Vector3(0, 0, 0),
              hotpoint: hits[i].pickedMesh,
              hotpointData: hotpointsData[value - 1]
            });
          }
        }
      }
    };
  }

  const moveActiveCamera = (scene:Scene, {
    alpha,
    beta,
    // @ts-ignore
    target: { x: targetX, y: targetY, z: targetZ },
    hotpoint,
    hotpointData
  }: {
    alpha: number;
    beta: number;
    target?: Vector3;
    hotpoint?: Nullable<AbstractMesh>;
    hotpointData?: any;
  }) => {
    if(!camera) return;

    camera.animations = [
      createAnimation({
        property: "beta",
        from: simplifyRadians(camera.beta),
        to: beta,
      }),
      createAnimation({
        property: "alpha",
        from: simplifyRadians(camera.alpha),
        to: alpha,
      }),
      createAnimation({
          property: "target.x",
          from: camera.target.x,
          to: targetX,
      }),
      createAnimation({
          property: "target.y",
          from: camera.target.y,
          to: targetY,
      }),
      createAnimation({
          property: "target.z",
          from: camera.target.z,
          to: targetZ,
      }),
    ];

    scene.beginAnimation(camera, FROM_FRAME, TO_FRAME, LOOP_MODE, SPEED_RATIO, () => {
      if(hotpointInfo !== undefined && hotpointInfo !== null &&
        hotpoint !== undefined && hotpoint !== null &&
        engine !== undefined && engine !== null) {
        const absolutePosition = hotpoint.absolutePosition;

        var hotpointPosition = Vector3.Project(
          absolutePosition !== undefined ? absolutePosition : Vector3.Zero(),
          Matrix.Identity(),
          scene.getTransformMatrix(),
          camera.viewport.toGlobal(engine.getRenderWidth(), engine.getRenderHeight())
        );

        setInfoWindowPosition(hotpointPosition);
        setHotpointInfo(hotpointData);
        setShowInfoWindow(true);
      }
    });
  }

  const createAnimation = ({ property, from, to } : {
    property: string;
    from: number;
    to: number;
  }) => {
    const ease = new CubicEase();
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

    const animation = Animation.CreateAnimation(
        property,
        Animation.ANIMATIONTYPE_FLOAT,
        FRAMES_PER_SECOND,
        ease
    );

    animation.setKeys([
      {
        frame: 0,
        value: from,
      },
      {
        frame: 100,
        value: to,
      },
    ]);

    return animation;
  }

  const simplifyRadians = (radians:any) => {
    const simplifiedRadians = radians % (2 * Math.PI);

    return simplifiedRadians < 0
        ? simplifiedRadians + Tools.ToRadians(360)
        : simplifiedRadians;
  }

  return (
    <div className="ArteffactScreenComponent">
      <a className="ButtonBack" href="#" onClick={(e) => {
        if(onClose !== undefined && onClose !== null)
          onClose();
      }} style={{
        position: "absolute",
        zIndex: 1000,
        top: '5px',
        left: '10px',
      }}>НАЗАД</a>
      <div className="screen-wallpaper">
        <div className="curved-background-box">
          <fieldset className="screen-wallpaper-fieldset">
            <legend>
              <div className="ArteffactScreenComponentTitleLeft">
                <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="logo" className="logo" style={{
                  paddingLeft: "50px"
                }} />
              </div>
              <div className="ArteffactScreenComponentTitleRight">
                <div className="ArteffactScreenComponentTitleRightTitle">
                  {arteffactMeta.hall ?? "Зала \"Антично наследство\""}
                </div>
                <div className="ArteffactScreenComponentTitleRightLogo">
                  <img src={process.env.PUBLIC_URL + '/images/' + (arteffactMeta.logo ?? 'arete-logo.png')} />
                </div>
              </div>
            </legend>
          </fieldset>
        </div>
        <div className="curved-background-container"></div>
      </div>
      <div className="InfoScreenComponent">
        <div className="ArteffactScreenComponentContent">
          <div className="ArteffactScreenComponentContentContent" style={{
            height: "100%",
          }}>
            <canvas id="arteffact-canvas" ref={reactCanvas} style={{
              width: "100%",
              height: "100%",
              outline: "none",
              border: "none"
            }}/>
            {
              showInfoWindow &&
              hotpointInfo !== undefined && hotpointInfo !== null &&
              infoWindowPosition !== undefined && infoWindowPosition !== null ? (
                <div className="InfoDiv" style={{
                  left: (infoWindowPosition.x > 0 ? infoWindowPosition.x + (hotpointInfo.infoAlignment == "left" ? -280 : 80) : -1000) + 'px',
                  top: (infoWindowPosition.y > 0 ? infoWindowPosition.y - 12 : -1000) + 'px'
                }}>
                  <div className="InfoDivHeader">{hotpointInfo.title}</div>
                  <div className={`InfoDivContent ${hotpointInfo.infoAlignment}`} dangerouslySetInnerHTML={{
                    __html: `${hotpointInfo.description}`
                  }}></div>
                </div>
              ) : null
            }
          </div>
        </div>
        <p style={{
          bottom: 0,
          color: '#3cabfa',
          fontSize: '22px',
          margin: '10px 0 20px 0',
          left: 0,
          position: 'absolute',
          right: 0,
          textAlign: 'center',
          zIndex: 1000,
        }}>{arteffactMeta.name}</p>
      </div>
    </div>
  )
}
