import { Engine, KeyboardEventTypes, KeyboardInfo, Scene, Sound } from "@babylonjs/core";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

const pressedKeys:{[key:string]: boolean} = {};

export interface SoundsDataContextInterface {
  initWalkingSounds: Function,
  initBackgroundSounds: Function,
  playBackgroundMusic: Function
}

export const SoundsDataContext = React.createContext({});

export const SoundsDataProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [audioSteps, setAudioSteps] = useState<Sound>();
  const [introSound, setIntroSound] = useState<Sound>();
  const [antichnostSound, setAnticnhnostSound] = useState<Sound>();
  const [vizantiaSound, setVizantiaSound] = useState<Sound>();
  const [audioEnabled, setAudioEnabled] = useState<boolean>(false);
  const [scene, setScene] = useState<Scene>();
  const observableKeys = ["w", "a", "s", "d", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"];

  useEffect(() => {
    if(audioEnabled && audioSteps && scene) {
      scene.onKeyboardObservable.add((kbInfo:KeyboardInfo) => {
        if (kbInfo.type === KeyboardEventTypes.KEYDOWN) {
          onKeyDown(kbInfo.event);
        } else if (kbInfo.type === KeyboardEventTypes.KEYUP) {
          onKeyUp(kbInfo.event);
        }
      });
    }
  }, [audioEnabled, audioSteps, scene]);

  useEffect(() => {
    const onClick = (event:any) => {
      setAudioEnabled(true);
      Engine.audioEngine?.audioContext?.resume();
    };

    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('click', onClick);
    }
  }, []);

  const onKeyDown = useCallback((event:any) => {
    if(observableKeys.includes(event.key)) {
      pressedKeys[event.key] = true;

      if(audioSteps?.isPlaying == false && audioEnabled) {
        audioSteps?.play();
      }
    }
  }, [audioSteps, audioEnabled]);

  const onKeyUp = useCallback((event:any) => {
    if(observableKeys.includes(event.key)) {
      delete pressedKeys[event.key];

      if(Object.keys(pressedKeys).length == 0) {
        audioSteps?.stop();
      }
    }
  }, [audioSteps]);

  const initWalkingSounds = useCallback((appScene:any) => {
    setScene(appScene);
    const audio = new Sound("steps", process.env.PUBLIC_URL + '/audio/steps.mp3', appScene, () => {
      audio.setVolume(0.3);
      setAudioSteps(audio);
    }, {
      loop: true,
      autoplay: false,
    });
  }, []);

  const initBackgroundSounds = useCallback((appScene:any) => {
    console.log("Init initBackgroundSounds");
    const audio = new Sound("intro", process.env.PUBLIC_URL + '/audio/Intro.mp3', appScene, () => {
      audio.setVolume(0.2);
      audio.play();
      setIntroSound(audio);
    }, {
      loop: true,
      autoplay: false,
    });

    const audio1 = new Sound("antichnost", process.env.PUBLIC_URL + '/audio/Antichnost.mp3', appScene, () => {
      audio1.setVolume(0.2);
      setAnticnhnostSound(audio1);
    }, {
      loop: true,
      autoplay: false,
    });

    const audio2 = new Sound("vizantia", process.env.PUBLIC_URL + '/audio/Vizantia.mp3', appScene, () => {
      audio2.setVolume(0.2);
      setVizantiaSound(audio2);
    }, {
      loop: true,
      autoplay: false,
    });
  }, []);

  const playBackgroundMusic = useCallback((name:string) => {
    switch(name) {
      case 'intro':
        introSound?.stop();
        antichnostSound?.stop();
        vizantiaSound?.stop();
        introSound?.play();
        break;
      case 'antichnost':
        introSound?.stop();
        antichnostSound?.stop();
        vizantiaSound?.stop();
        antichnostSound?.play();
        break;
      case 'vizantia':
        introSound?.stop();
        antichnostSound?.stop();
        vizantiaSound?.stop();
        vizantiaSound?.play();
        break;
    }
  }, [introSound, antichnostSound, vizantiaSound, audioEnabled, scene]);

  const contextValue = {
    initWalkingSounds,
    initBackgroundSounds,
    playBackgroundMusic
  }

  return (
    <SoundsDataContext.Provider value={contextValue}>{children}</SoundsDataContext.Provider>
  );
};

export const useWalkingSounds = () => useContext(SoundsDataContext) as SoundsDataContextInterface;
