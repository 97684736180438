import { useCallback, useEffect, useRef, useState } from "react";
import { AbstractMesh, ActionManager, Color3, Color4, CubeTexture, DirectionalLight, Engine, EngineOptions, ExecuteCodeAction, FreeCamera, HemisphericLight, KeyboardEventTypes, Light, Material, Mesh, MeshBuilder, Nullable, PBRMaterial, PointerEventTypes, Scene, SceneLoader, SceneOptions, Sound, StandardMaterial, Texture, Vector3, VideoTexture } from "@babylonjs/core";
import "@babylonjs/loaders";
import "@babylonjs/core/Materials/PBR/pbrBaseMaterial"
import { IFileData } from "../interfaces/IFileData";
import { level1Files, level2Files, level3Files } from "../data/levels";
import { people } from "../data/people";
import { useAnimations } from "../hooks/useAnimations";
import { LottieTexture } from "babylonjs-lottie";
import { arrowsPositions } from "../data/arrows";
import { useWalkingSounds } from "../hooks/useWalkingSounds";
import { useLoadEntity } from "../hooks/useLoadEntity";
import { Door, useDoors } from "../hooks/useDoors";
import { useVideos } from "../hooks/useVideos";
import { sceneVideos } from "../data/videos";

export default ({
  adaptToDeviceRatio,
  antialias,
  engineOptions,
  sceneOptions,
  onRender,
  onSceneReady,
  onSceneLoaded,
  onMeshClicked,
  id,
  ...rest
}:{
  antialias?: boolean;
  engineOptions?: EngineOptions;
  adaptToDeviceRatio?: boolean;
  sceneOptions?: SceneOptions;
  id?: string;
  onRender?: (scene: Scene) => void;
  onSceneReady: (scene: Scene) => void;
  onSceneLoaded?: (scene: Scene) => void;
  onMeshClicked?: (mesh: AbstractMesh) => void;
}) => {
  const reactCanvas = useRef(null);
  const [camera, setCamera] = useState<FreeCamera>();
  const [engine, setEngine] = useState<Engine>();
  const [scene, setScene] = useState<Scene>();
  const {initWalkingSounds, initBackgroundSounds} = useWalkingSounds();
  const {rotationAnimation} = useAnimations();
  const {canLoadEntities, LoadEntity} = useLoadEntity({
    onMeshClicked,
    scene
  });
  const {CreateDoor} = useDoors({
    camera,
    onMeshClicked,
    scene,
  });
  const {videoObjects} = useVideos(
    {
      camera,
      scene,
      videos: sceneVideos
    }
  );

  const [door, setDoor] = useState<Door | null>(null);
  const [lights, setLights] = useState<Light[]>([]);
  const lightConfigurations: any[] = [
    {alpha: 0, beta: 1, gamma: 0, intensity: 0.5, diffuse: new Color3(1, 1, 1), specular: new Color3(1, 1, 1), enabled: true},
    // {alpha: 0.1, beta: 0, gamma: 0, intensity: 5, diffuse: new Color3(1, 0, 0), specular: new Color3(1, 1, 1), enabled: true},
    // {alpha: 120, beta: 0, gamma: 0, intensity: 5, diffuse: new Color3(0, 1, 0), specular: new Color3(1, 1, 1), enabled: true},
    // {alpha: 240, beta: 0, gamma: 0, intensity: 5, diffuse: new Color3(0, 0, 1), specular: new Color3(1, 1, 1), enabled: true},
  ];

  const CreateScene = () => {
    const { current: canvas } = reactCanvas;
    const engine = new Engine(canvas, antialias, engineOptions, adaptToDeviceRatio);
    setEngine(engine);

    const scene = new Scene(engine, sceneOptions);
    scene.actionManager = new ActionManager(scene);
    scene.clearColor = new Color4(191 / 255, 219 / 255, 255 / 255, 1);

    const framesPerSecond = 60;
    const gravity = -9.81;
    scene.gravity = new Vector3(0, gravity / framesPerSecond, 0);
    scene.collisionsEnabled = true;
    setScene(scene);

    const camera = new FreeCamera("camera", new Vector3(-15, 1.5, 59), scene);
    // const camera = new FreeCamera("camera", new Vector3(7.5, 1.720114, -18.1383), scene);
    camera.rotation = new Vector3(-Math.PI / 36, 16.5 * Math.PI / 18, 0);
    camera.attachControl(canvas, true);

    camera.applyGravity = true;
    camera.checkCollisions = true;

    camera.ellipsoid = new Vector3(0.25, 0.5, 0.25);
    camera.ellipsoidOffset = new Vector3(0, -0.75, 0);

    camera.minZ = 0.1;
    camera.speed = 0.15;
    camera.angularSensibility = 4000;

    camera.keysUp.push(87);
    camera.keysLeft.push(65);
    camera.keysDown.push(83);
    camera.keysRight.push(68);

    setCamera(camera);
  }

  const CreateEnvironment = async () : Promise<void> => {
    if(scene === undefined || scene === null)
      return;

    // const envTex = CubeTexture.CreateFromPrefilteredData(
    //   "./environment/sky.env",
    //   scene
    // );

    // scene.environmentTexture = envTex;

    // Create ground
    const ground = MeshBuilder.CreateGround("ground", {
      width: 1000,
      height: 1000,
      subdivisions: 1
    }, scene);

    ground.checkCollisions = true;
    ground.position = new Vector3(0, -0.005, 0);

    // Load Level 3
    await Promise.all(level3Files.map(async (file): Promise<void> => {
      await LoadEntity(file, scene);
      return Promise.resolve();
    }));

    // Load Level 2
    await Promise.all(level2Files.map(async (file): Promise<void> => {
      await LoadEntity(file, scene);
      return Promise.resolve();
    }));

    // Load Level 1
    await Promise.all(level1Files.map(async (file): Promise<void> => {
      await LoadEntity(file, scene);
      return Promise.resolve();
    }));

    const door = await CreateDoor(new Vector3(0, 0, 0));
    setDoor(door);
  }

  const CreateLights = (): void => {
    lightConfigurations.map((lightConfig, index) => {
      const light = new HemisphericLight(
        "light" + index,
        new Vector3(
          lightConfig.alpha,
          lightConfig.beta,
          lightConfig.gamma
        ),
        scene
      );
      light.intensity = lightConfig.intensity;
      light.diffuse = lightConfig.diffuse;
      light.specular = lightConfig.specular;
      light.setEnabled(lightConfig.enabled);
      // if(index == 0)
      // this.CreateGizmos(light);

      // light.shadowEnabled = true;
      // light.shadowMinZ = 0;
      // light.shadowMaxZ = 100;
      // light.autoCalcShadowZBounds = true;
      // this.shadowGen = new ShadowGenerator(1024, light);
      // if(this.shadowGen) {
      //   this.shadowGen.blurScale = 2;
      //   this.shadowGen.useBlurCloseExponentialShadowMap = true;
      // }

      lights.push(light);
    });
  }

  const CreatePeople = async () : Promise<void> => {
    if(scene === undefined || scene === null)
      return;

    const mesh = await LoadEntity(people[0], scene);
    const peopleMaterial:Nullable<Material> = mesh[1].material;

    await Promise.all(people.map(async (file, index): Promise<void> => {
      if(index > 0) {
        const mesh = await LoadEntity(file, scene);
        mesh.forEach(m => {
          if(peopleMaterial !== null && m.name !== "__root__") {
            m.material = peopleMaterial;
          }
        });
      }

      return Promise.resolve();
    }));
  }

  const CreateAnimations = async (scene: Scene): Promise<void> => {
    const rotationForward = rotationAnimation(10, "y", "forward");
    const rotationBackward = rotationAnimation(10, "y", "backward");

    const neoLogo = scene.getMeshByName("Neo_logo");
    if(neoLogo !== null) {
      neoLogo.rotationQuaternion = null;
      neoLogo.rotation = new Vector3(-Math.PI/2, 0, 0);
      scene.beginDirectAnimation(neoLogo, [rotationForward], 0, 180, true);
    }

    const vrLogo = scene.getMeshByName("VR_Logo");
    if(vrLogo !== null) {
      vrLogo.rotationQuaternion = null;
      vrLogo.rotation = new Vector3(-Math.PI/2, 0, 0);
      scene.beginDirectAnimation(vrLogo, [rotationBackward], 0, 180, true);
    }

    const vultusLogo = scene.getMeshByName("Vultus_Logo");
    if(vultusLogo !== null) {
      vultusLogo.rotationQuaternion = null;
      vultusLogo.rotation = new Vector3(-Math.PI/2, 0, 0);
      scene.beginDirectAnimation(vultusLogo, [rotationForward], 0, 180, true);
    }

    const appleObject = scene.getMeshByName("Apple_Pbject");
    if(appleObject !== null) {
      appleObject.rotationQuaternion = null;
      appleObject.rotation = new Vector3(-Math.PI/2, 0, 0);
      scene.beginDirectAnimation(appleObject, [rotationForward], 0, 180, true);
    }
  }

  const CreateArrows = async (scene: Scene): Promise<void> => {
    // Create arrows
    let arrowBig = await CreateAnimatedArrow(scene, "./animations/Arrow_Clear.json");
    let arrowSmall = await CreateAnimatedArrow(scene, "./animations/Arrow_Half.json");
    let arrow = null;

    arrowsPositions.map((position, index) => {
      switch (position[6]) {
        case 2:
          arrow = arrowSmall.clone("arrow" + index);
          break;
        default:
          arrow = arrowBig.clone("arrow" + index);
          break;
      }

      arrow.position = new Vector3(position[0], position[1], position[2]);
      arrow.rotation = new Vector3(position[3], position[4], position[5]);
    });

    arrowBig.dispose();
    arrowSmall.dispose();
  }

  const CreateAnimatedArrow = async (scene: Scene, arrowPath: string = "./animations/Arrow_Clear.json"): Promise<Mesh> => {
    const plane = MeshBuilder.CreatePlane("arrow", {
      width: 0.5,
      height: 0.5,
      sideOrientation: Mesh.DOUBLESIDE
    }, scene);

    let mat = new PBRMaterial("arrow_material", scene)
    let lottieTexture = await LottieTexture.LoadFromUrlAsync("arrow_animation", arrowPath, scene, {
      useAnimeSize: false, // If true then use the width and height set in the animation file
      autoPlay: true,
      width: 512,
      height: 512,
      loop: true,
    });
    lottieTexture.hasAlpha = true;
    mat.albedoTexture = lottieTexture;
    mat.unlit = true;
    plane.material = mat;
    plane.rotation = new Vector3(Math.PI / 2, Math.PI / 2, 0);

    return plane;
  }

  const LoadScene = async () : Promise<void> => {
    if (!scene || !engine) return;

    CreateLights();
    await CreateEnvironment();
    await CreateArrows(scene);
    await CreatePeople();
    initWalkingSounds(scene);
    initBackgroundSounds(scene);
    await CreateAnimations(scene);
    onSceneLoaded?.(scene);
  }

  // set up basic engine and scene
  useEffect(() => {
    const { current: canvas } = reactCanvas;

    if (!canvas) return;

    CreateScene();
  }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady]);

  // bind scene and engine actions
  useEffect(() => {
    if (!scene || !engine || !canLoadEntities) return;

    if (scene.isReady()) {
      onSceneReady(scene);
    } else {
      scene.onReadyObservable.addOnce((scene) => onSceneReady(scene));
    }

    LoadScene();

    engine.runRenderLoop(() => {
      if (typeof onRender === "function") onRender(scene);
      scene.render();
    });

    const resize = () => {
      scene.getEngine().resize();
    };

    if (window) {
      window.addEventListener("resize", resize);
    }

    return () => {
      scene.getEngine().dispose();

      if (window) {
        window.removeEventListener("resize", resize);
      }
    };
  }, [scene, engine, onRender, onSceneReady, canLoadEntities]);

  return <canvas id={id} ref={reactCanvas} {...rest} />;
};
