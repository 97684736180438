import { AbstractMesh, ActionManager, Color3, ExecuteCodeAction, Mesh, PBRMaterial, Scene, SceneLoader, StandardMaterial, Texture } from "@babylonjs/core";
import { IFileData } from "../interfaces/IFileData";
import { useCallback, useEffect, useState } from "react";

export const useLoadEntity = ({
  scene,
  onMeshClicked,
  ...rest
}:{
  scene?: Scene;
  onMeshClicked?: (mesh: AbstractMesh) => void;
}) => {
  const [arteffactScreenHoverMaterial, setArteffactScreenHoverMaterial] = useState<PBRMaterial>();
  const [arteffactScreenOutMaterial, setArteffactScreenOutMaterial] = useState<PBRMaterial>();
  const [canLoadEntities, setCanLoadEntities] = useState<boolean>(false);

  // On first run, set initial count
  useEffect(() => {
    if(!scene) return;

    if (arteffactScreenHoverMaterial === undefined) {
      const material = new PBRMaterial("arteffactScreenHoverMaterial", scene);
      material.albedoTexture = new Texture("images/artefact-ui-button.png", scene, true, false);
      material.albedoTexture.level = 10;
      material.unlit = true;
      material.albedoTexture.hasAlpha = true;
      setArteffactScreenHoverMaterial(material);
    }

    if (arteffactScreenOutMaterial === undefined) {
      const material = new PBRMaterial("arteffactScreenOutMaterial", scene);
      material.albedoTexture = new Texture("images/artefact-ui-frame.png", scene, true, false);
      material.albedoTexture.level = 10;
      material.unlit = true;
      material.albedoTexture.hasAlpha = true;
      setArteffactScreenOutMaterial(material);
    }

    ApplyGeneralActions([], scene)

    setCanLoadEntities(true);
  }, [scene]);

  const LoadEntity = useCallback(async(fileData: IFileData, scene: Scene): Promise<Mesh[]> => {
    const { meshes } = await SceneLoader.ImportMeshAsync(
      null, fileData.folder, fileData.file, scene
    );

    ApplyLightmaps(fileData, meshes, scene);
    ApplyActions(meshes, scene);

    if(fileData.should_apply_collisions) {
      meshes.forEach(mesh => {
        mesh.checkCollisions = true;
      });
    }

    if(fileData.position !== undefined) {
      meshes[0].position = fileData.position;
    }

    if(fileData.rotation !== undefined) {
      meshes[0].rotation = fileData.rotation;
    }

    if(fileData.scaling !== undefined) {
      meshes[0].scaling.scaleInPlace(fileData.scaling);
    }

    // texture intensity
    meshes.forEach(mesh => {
      if(mesh.name.includes("IlluminatingBoard") === true) {
        if(mesh !== null && mesh.material !== null) {
          mesh.material = mesh.material.clone(mesh.name + "_material");
          const pbrMaterial = (mesh.material as PBRMaterial);

          if(pbrMaterial !== null && pbrMaterial.albedoTexture !== null) {
            if(mesh.name.includes("K_INFO")) {
              pbrMaterial.albedoTexture.level = 2;
            } else if(mesh.name.includes("D_INFO")) {
              pbrMaterial.albedoTexture.level = 2;
            } else if(mesh.name.includes("V_INFO")) {
              const videoMaterial = (mesh.material as StandardMaterial);
              // @ts-ignore
              videoMaterial.diffuseTexture.level = 1;
            } else if(mesh.name.includes("Eff")) {
              pbrMaterial.albedoTexture.level = 0.5;
            } else if(mesh.name.includes("Oko")) {
              pbrMaterial.albedoTexture.level = 5;
            } else if(mesh.name.includes("Art")) {
              pbrMaterial.albedoTexture.level = 0.5;
            }
          }
        }
      }

      if(mesh.name.includes("COLLISION")) {
        const pbrMaterial = (mesh.material as PBRMaterial);
        pbrMaterial.albedoColor = new Color3(0.5, 0.5, 0.5);
        pbrMaterial.transparencyMode = 2;
        pbrMaterial.alpha = 0;
      }
    });

    return meshes as Mesh[];
  }, [scene]);

  const ApplyLightmaps = useCallback((fileData: IFileData, meshes: AbstractMesh[], scene: Scene) => {
    const lmArray = fileData.lightmaps ?? [];

    lmArray.forEach(lm => {
      const lmTexture = new Texture(fileData.folder + lm, scene);
      const lmTextureStaticBoards = new Texture(fileData.folder + 'LM_B_new_Pana_Light.jpg', scene);
      const excludeMeshNames = fileData.lightmaps_exclude ?? [];

      meshes.forEach(mesh => {
        let shouldExclude = false;
        excludeMeshNames.forEach(element => {
          if(mesh.name.includes(element)) {
            shouldExclude = true;
            return;
          }
        });

        if (mesh.material && !shouldExclude)
        {
          //@ts-ignore
          if(mesh.name.includes("K_INFO")) {
            //@ts-ignore
            mesh.material.lightmapTexture = lmTextureStaticBoards;
          } else {
            //@ts-ignore
            mesh.material.lightmapTexture = lmTexture;
          }

          //@ts-ignore
          mesh.material.useLightmapAsShadowmap = true;
          //@ts-ignore
          mesh.material.lightmapTexture.uAng = Math.PI;

          if(fileData.lightmaps_intensity !== undefined) {
            //@ts-ignore
            mesh.material.lightmapTexture.level = fileData.lightmaps_intensity;
          }

          //@ts-ignore
          mesh.material.lightmapTexture.coordinatesIndex = 1;
        }
      });
    });
  }, []);

  const ApplyGeneralActions = useCallback((meshes: AbstractMesh[], scene: Scene) => {
    const actionManager = scene?.actionManager;

    actionManager?.registerAction(
      new ExecuteCodeAction(ActionManager.OnPickTrigger, RunEvent)
    );
    actionManager?.registerAction(
      new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, OnBoardOver)
    );
    actionManager?.registerAction(
      new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, OnBoardOut)
    );
  }, [scene]);

  const ApplyActions = useCallback((meshes: AbstractMesh[], scene: Scene) => {
    const actionManager = scene?.actionManager;

    meshes.forEach(mesh => {
      if(
        (mesh.name.includes("IlluminatingBoard") &&
        (
          mesh.name.includes("D_INFO") ||
          mesh.name.includes("V_INFO") ||
          mesh.name.includes("Eff") ||
          mesh.name.includes("Oko")
        ) || (
          mesh.name.includes("Xolograma_Kristiana_Play") ||
          mesh.name.includes("Xolograma_Natalya_Play")
        )) &&
        actionManager !== undefined
      ) {
        mesh.actionManager = actionManager;
      }
    });
  }, [scene]);

  const RunEvent = useCallback((meshEvent:any) => {
    const pickedMesh = meshEvent.meshUnderPointer;

    if(
      !pickedMesh.name.includes("D_INFO") &&
      !pickedMesh.name.includes("V_INFO") &&
      !pickedMesh.name.includes("IlluminatingBoard") &&
      !pickedMesh.name.includes("Eff") &&
      !pickedMesh.name.includes("Oko")
      )
      return;

    onMeshClicked?.(pickedMesh);
  }, [onMeshClicked]);

  const OnBoardOver = useCallback((meshEvent:any) => {
    const pbrMaterial = meshEvent.meshUnderPointer.material as PBRMaterial;

    if(meshEvent.meshUnderPointer.name.includes("D_INFO") && pbrMaterial !== null && pbrMaterial.albedoTexture !== null) {
      pbrMaterial.albedoTexture.level = 2;
    }

    if(meshEvent.meshUnderPointer.name.includes("V_INFO")) {
      const videoMaterial = (meshEvent.meshUnderPointer.material as StandardMaterial);
      // @ts-ignore
      videoMaterial.diffuseTexture.level = 1;
    }

    if(
      meshEvent.meshUnderPointer.name.includes("Oko")) {
      let mesh = meshEvent.meshUnderPointer;

      if(mesh !== null && mesh !== undefined) {
        const effMesh = scene?.getMeshByName(mesh.name.replace("Oko_", "Eff_"));
        const effPbrMaterial = effMesh?.material as PBRMaterial;
        if(effPbrMaterial !== null && effPbrMaterial.albedoTexture !== null) {
          effPbrMaterial.albedoTexture.level = 20;
        }
        const artMesh = scene?.getMeshByName(mesh.name.replace("Oko_", "Art_"));
        const artPbrMaterial = artMesh?.material as PBRMaterial;
        if(artPbrMaterial !== null && artPbrMaterial.albedoTexture !== null) {
          artPbrMaterial.albedoTexture.level = 2;
        }

        const material = scene?.getMaterialByName("arteffactScreenHoverMaterial");
        if(material !== null && material !== undefined) {
          mesh.material = material;
        }
      }
    }
  }, [arteffactScreenHoverMaterial, scene]);

  const OnBoardOut = useCallback((meshEvent:any) => {
    const pbrMaterial = meshEvent.meshUnderPointer.material as PBRMaterial;

    if(meshEvent.meshUnderPointer.name.includes("D_INFO") && pbrMaterial !== null && pbrMaterial.albedoTexture !== null) {
      pbrMaterial.albedoTexture.level = 2;
    }

    if(meshEvent.meshUnderPointer.name.includes("V_INFO")) {
      const videoMaterial = (meshEvent.meshUnderPointer.material as StandardMaterial);
      // @ts-ignore
      videoMaterial.diffuseTexture.level = 1;
    }

    if(
      meshEvent.meshUnderPointer.name.includes("Oko")) {
      let mesh = meshEvent.meshUnderPointer;

      if(mesh !== null && mesh !== undefined) {
        const effMesh = scene?.getMeshByName(mesh.name.replace("Oko_", "Eff_"));
        const effPbrMaterial = effMesh?.material as PBRMaterial;
        if(effPbrMaterial !== null && effPbrMaterial.albedoTexture !== null) {
          effPbrMaterial.albedoTexture.level = 0.5;
        }
        const artMesh = scene?.getMeshByName(mesh.name.replace("Oko_", "Art_"));
        const artPbrMaterial = artMesh?.material as PBRMaterial;
        if(artPbrMaterial !== null && artPbrMaterial.albedoTexture !== null) {
          artPbrMaterial.albedoTexture.level = 0.5;
        }

        const material = scene?.getMaterialByName("arteffactScreenOutMaterial");
        if(material !== null && material !== undefined) {
          mesh.material = material;
        }
      }
    }
  }, [arteffactScreenOutMaterial, scene]);

  return {
    canLoadEntities,
    LoadEntity,
    RunEvent
  }
}
