export const arrowsPositions = [
  // big arrows
  [-1.4, 0.1, 3, Math.PI / 2, 0, 0, 1],
  [0.3, 0.1, -12, -Math.PI / 2, Math.PI / 2, 0, 1],
  [-3.2, 0.1, -12, Math.PI / 2, Math.PI / 2, 0, 1],
  [-7.2, 0.1, -15.3, Math.PI / 2, Math.PI / 2, 0, 1],
  [4.5, 0.1, -15.3, -Math.PI / 2, Math.PI / 2, 0, 1],
  [-11.3, 0.1, -15.3, Math.PI / 2, Math.PI / 2, 0, 1],
  [-15.2, 0.1, -17, -Math.PI / 2, Math.PI / 2, 0, 1],
  [-15.2, 0.1, -13.5, Math.PI / 2, Math.PI / 2, 0, 1],
  [10.9, 0.1, -13.5, -Math.PI / 2, Math.PI / 2, 0, 1],
  [10.9, 0.1, -17, -Math.PI / 2, Math.PI / 2, 0, 1],
  [8.75, 0.1, -17, Math.PI / 2, 0, 0, 1],
  [8.75, 0.1, -13.5, Math.PI / 2, -Math.PI, 0, 1],
  [8.5, 0.1, -6, -Math.PI / 2, Math.PI / 2, 0, 1],
  [12.3, 0.1, -7.65, -Math.PI / 2, Math.PI / 2, 0, 1],
  [12.3, 0.1, -4.2, Math.PI / 2, Math.PI / 2, 0, 1],
  [8.5, 0.1, -25, -Math.PI / 2, Math.PI / 2, 0, 1],
  [12.3, 0.1, -26.7, -Math.PI / 2, Math.PI / 2, 0, 1],
  [12.3, 0.1, -23.25, Math.PI / 2, Math.PI / 2, 0, 1],
  [10.83, 2, -14.65, 0, Math.PI / 2, -Math.PI / 2, 1],
  [10.83, 2, -16.1, 0, Math.PI / 2, Math.PI / 2, 1],

  //small arrows
  [-19.12, 0.1, -15.21, Math.PI / 2, Math.PI / 2, 0, 2],
  [-20.96, 0.1, -15.21, -Math.PI / 2, Math.PI / 2, 0, 2],
  [-22.037, 0.1, -15.21, Math.PI / 2, Math.PI / 2, 0, 2],
  [-23.86, 0.1, -15.21, -Math.PI / 2, Math.PI / 2, 0, 2],
  [-25.12, 0.1, -15.21, Math.PI / 2, Math.PI / 2, 0, 2],
  [-26.95, 0.1, -15.21, -Math.PI / 2, Math.PI / 2, 0, 2],
  [-28.105, 0.1, -15.21, Math.PI / 2, Math.PI / 2, 0, 2],
  [-29.932, 0.1, -15.21, -Math.PI / 2, Math.PI / 2, 0, 2],
  [-30.983, 0.1, -15.21, Math.PI / 2, Math.PI / 2, 0, 2],
  [-32.815, 0.1, -15.21, -Math.PI / 2, Math.PI / 2, 0, 2],
  [18.18, 0.1, -24.77, Math.PI / 2, Math.PI / 2, 0, 2],
  [19.35, 0.1, -24.77, -Math.PI / 2, Math.PI / 2, 0, 2],
  [21.18, 0.1, -24.77, Math.PI / 2, Math.PI / 2, 0, 2],
  [22.345, 0.1, -24.77, -Math.PI / 2, Math.PI / 2, 0, 2],
  [24.177, 0.1, -24.77, Math.PI / 2, Math.PI / 2, 0, 2],
  [25.329, 0.1, -24.77, -Math.PI / 2, Math.PI / 2, 0, 2],
  [27.161, 0.1, -24.77, Math.PI / 2, Math.PI / 2, 0, 2],
  [28.203, 0.1, -24.77, -Math.PI / 2, Math.PI / 2, 0, 2],
  [30.037, 0.1, -24.77, Math.PI / 2, Math.PI / 2, 0, 2],
  [18.18, 0.1, -6.1, Math.PI / 2, Math.PI / 2, 0, 2],
  [19.35, 0.1, -6.1, -Math.PI / 2, Math.PI / 2, 0, 2],
  [21.18, 0.1, -6.1, Math.PI / 2, Math.PI / 2, 0, 2],
  [22.345, 0.1, -6.1, -Math.PI / 2, Math.PI / 2, 0, 2],
  [24.177, 0.1, -6.1, Math.PI / 2, Math.PI / 2, 0, 2],
  [25.329, 0.1, -6.1, -Math.PI / 2, Math.PI / 2, 0, 2],
  [27.161, 0.1, -6.1, Math.PI / 2, Math.PI / 2, 0, 2],
  [28.203, 0.1, -6.1, -Math.PI / 2, Math.PI / 2, 0, 2],
  [30.037, 0.1, -6.1, Math.PI / 2, Math.PI / 2, 0, 2],
];
