import { AbstractMesh, ActionManager, Color3, ExecuteCodeAction, FreeCamera, Mesh, MeshBuilder, Scene, StandardMaterial, Texture, Vector3, VideoTexture } from "@babylonjs/core";
import { useCallback, useEffect, useState } from "react";
import { IVideo } from "../interfaces/IVideo";

export class VideoObject {
  mesh: Mesh;
  texture: VideoTexture;
  video: IVideo;

  constructor(mesh: Mesh, texture: VideoTexture, video: IVideo) {
    this.mesh = mesh;
    this.texture = texture;
    this.video = video;
  }
}

export const useVideos = ({
  camera,
  scene,
  videos
}:{
  camera?: FreeCamera;
  scene?: Scene;
  videos?: IVideo[];
}) => {
  const [videoObjects, setVideoObjects] = useState<VideoObject[]>();

  useEffect(() => {
    if(!scene || !videos)
      return;

    const createVideoObjects = async () => {
      window.removeEventListener('click', createVideoObjects);
      const videoObjects: VideoObject[] = [];

      for (let index = 0; index < videos.length; index++) {
        const video = videos[index];
        const videoObject = await CreateVideoObject(video);
        videoObjects.push(videoObject);
      }

      setVideoObjects(videoObjects);
    };

    window.addEventListener('click', createVideoObjects);

    return () => {
      window.removeEventListener('click', createVideoObjects);
    }
  }, [scene]);

  useEffect(() => {
    if(!scene || !camera || !videos || !videoObjects)
      return;

    ApplyGeneralActions([], scene)

    // const observable = scene.onBeforeRenderObservable.add((eventData, eventState) => {
    //   videoObjects.forEach((videoObject) => {
    //     const cameraPosition = camera.position;
    //     const meshPosition = videoObject.mesh.position;
    //     const distance = Vector3.Distance(cameraPosition, meshPosition);

    //     if(videoObject.texture.video.readyState >= 4) {
    //       if (distance <= videoObject.video.activationDistance) {
    //         if(videoObject.texture.video.paused) {
    //           videoObject.texture.video.play();
    //         }
    //       } else {
    //         if(!videoObject.texture.video.paused) {
    //           videoObject.texture.video.pause();
    //         }
    //       }
    //     }
    //   });
    // });

    // return () => {
    //   scene.onBeforeRenderObservable.remove(observable);
    // }
  }, [videoObjects]);

  const ApplyGeneralActions = useCallback((meshes: AbstractMesh[], scene: Scene) => {
    const actionManager = scene?.actionManager;

    actionManager?.registerAction(
      new ExecuteCodeAction(ActionManager.OnPickTrigger, RunEvent)
    );
  }, [scene, videoObjects]);

  const RunEvent = useCallback((meshEvent:any) => {
    const pickedMesh = meshEvent.meshUnderPointer;

    if(
      !pickedMesh.name.includes("Xolograma_Kristiana_Play") &&
      !pickedMesh.name.includes("Xolograma_Natalya_Play")
      )
      return;

    let videoName = "HologramNataliya";

    switch (pickedMesh.name) {
      case "Xolograma_Natalya_Play":
        videoName = "HologramNataliya";
        break;
      case "Xolograma_Kristiana_Play":
        videoName = "HologramKristiana";
        break;
    }

    const videoObject = videoObjects?.find((videoObject) => videoObject.video.name === videoName);

    if(videoObject && videoObject.texture.video.readyState >= 4) {
      const video = videoObject.texture.video;
      if(video.paused) {
        pickedMesh.material.albedoTexture = new Texture("images/Play_Button_Sound.png", scene, true, false);
        video.play();
      } else {
        pickedMesh.material.albedoTexture = new Texture("images/Play_Button_PLAY.png", scene, true, false);
        video.pause();
      }
    }
  }, [videoObjects]);

  const CreateVideoObject = async (videoData:IVideo): Promise<VideoObject> => {
    const planeOpts = {
			height: videoData.height,
			width: videoData.width,
			sideOrientation: Mesh.DOUBLESIDE
    };
    const ANote0Video = MeshBuilder.CreatePlane("V_INFO" + videoData.name + "_IlluminatingBoard", planeOpts, scene);
    if((scene?.actionManager !== null || scene?.actionManager !== undefined) && !videoData.name.includes('Hologram')) {
      // @ts-ignore
      ANote0Video.actionManager = scene?.actionManager;
    }
    ANote0Video.position = videoData.position;

    if(videoData.rotation)
      ANote0Video.rotation = videoData.rotation;

    var ANote0VideoMat = new StandardMaterial("hologram_material", scene);
    // @ts-ignore
    // const existingVidetoTexture = scene?.getTextureByName(videoData.videoName) as VideoTexture;
    // var ANote0VideoVidTex = !existingVidetoTexture ? new VideoTexture(videoData.videoName, videoData.path, scene !== undefined ? scene : null) : existingVidetoTexture;
    var ANote0VideoVidTex = new VideoTexture(videoData.videoName, videoData.path, scene !== undefined ? scene : null);
    ANote0VideoMat.diffuseTexture = ANote0VideoVidTex;
    ANote0VideoMat.roughness = 1;
    ANote0VideoMat.emissiveColor = Color3.White();
    ANote0Video.material = ANote0VideoMat;
    if(videoData.name !== "HologramNataliya" && videoData.name !== "HologramKristiana") {
      ANote0VideoVidTex.video.autoplay = true;
    } else {
      ANote0VideoVidTex.video.autoplay = false;
    }
    ANote0VideoVidTex.video.volume = 1;

    // if(videoData.muted) {
    //   ANote0VideoVidTex.video.muted = videoData.muted;
    // }

    return new VideoObject(ANote0Video, ANote0VideoVidTex, videoData);
  }

  return {
    videoObjects
  }
}
