import { Vector3 } from "@babylonjs/core";
import { IFileData } from "../interfaces/IFileData";

export const level1Files: IFileData[]  = [
  {
    folder: "./models/Level_1_ZALI_IN/",
    file: "Zala_Enter_Front.glb",
    lightmaps: [
      "LM_B_new_Pana_Light.jpg"
    ],
    lightmaps_intensity: 5,
    should_apply_collisions: true
  },
  {
    folder: "./models/Level_1_ZALI_IN/",
    file: "Zala_Enter_Back.glb",
    lightmaps: [
      "LM_B_new_Pana_Light.jpg"
    ],
    lightmaps_intensity: 5,
    should_apply_collisions: true
  },
  {
    folder: "./models/Level_1_ZALI_IN/",
    file: "Zala_Antichnost.glb",
    lightmaps: [
      "LM_B_new_Pana_Light.jpg"
    ],
    lightmaps_intensity: 5,
    lightmaps_exclude: ["Oko_", "1_1_IlluminatingBoard"],
    should_apply_collisions: true
  },
  {
    folder: "./models/Level_1_ZALI_IN/",
    file: "Zali_Tabeli_All.glb",
    lightmaps: [],
    lightmaps_intensity: 5,
    lightmaps_exclude: [],
    should_apply_collisions: false
  },
  {
    folder: "./models/Level_1_ZALI_IN/",
    file: "Zala_Ranno_Xpistianstvo.glb",
    lightmaps: [
      "LM_B_new_Pana_Light.jpg"
    ],
    lightmaps_intensity: 5,
    should_apply_collisions: true
  },
  {
    folder: "./models/Level_1_ZALI_IN/",
    file: "Zala_Vazrazhdane.glb",
    lightmaps: [
      "LM_B_new_Pana_Light.jpg"
    ],
    lightmaps_intensity: 5,
    should_apply_collisions: true
  },
  {
    folder: "./models/Level_1_ZALI_IN/",
    file: "Zala_Xristianstvo_Obshta.glb",
    lightmaps: [
      "LM_B_new_Pana_Light.jpg"
    ],
    lightmaps_exclude: ["Xolograma_Kristiana_Play", "Xolograma_Natalya_Play"],
    lightmaps_intensity: 5,
    should_apply_collisions: true
  }
];

export const level2Files: IFileData[]  = [
  {
    folder: "./models/Level_2_OUT/",
    file: "001_OUT_Glass_ALL.glb",
    lightmaps: [],
    lightmaps_intensity: 5,
    should_apply_collisions: false
  },
  {
    folder: "./models/Level_2_OUT/",
    file: "001_Flora_1.glb",
    lightmaps: [],
    lightmaps_intensity: 5,
    should_apply_collisions: false
  },
  {
    folder: "./models/Level_2_OUT/",
    file: "001_OUT_Building_ALL.glb",
    lightmaps: [
      "Level_2_OUT.jpg"
    ],
    lightmaps_intensity: 5,
    should_apply_collisions: true
  },
];

export const level3Files: IFileData[] = [
  {
    folder: "./models/Level_3_ENV/",
    file: "Collision.glb",
    lightmaps: [],
    lightmaps_intensity: 5,
    should_apply_collisions: true
  },
  {
    folder: "./models/Level_3_ENV/",
    file: "003_Flora_2.glb",
    lightmaps: [],
    lightmaps_intensity: 5,
    should_apply_collisions: false
  },
  {
    folder: "./models/Level_3_ENV/",
    file: "003_Enviroment_BG.glb",
    lightmaps: [
      "Level_3_ENV.jpg"
    ],
    lightmaps_intensity: 5,
    should_apply_collisions: false
  },
  // {
  //   folder: "./models/Level_3_ENV/",
  //   file: "003_Sky.glb",
  //   lightmaps: [],
  //   lightmaps_intensity: 5,
  //   should_apply_collisions: false
  // },
];
